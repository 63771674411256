<template>
  <v-app>
    <v-container id="staffCardPage" class="d-flex justify-center" fluid>
      <div class="inner-wrap">
        <img class="company-logo" src="../assets/I-Charge_Tagline Logo RGB_Master Logo_H.png" width="100%" height="auto" style="margin-bottom: 20px"></img>

        <v-row class="justify-center">
          <!-- First Column -->
          <v-col cols="12" md="7" class="text-center align-content-center">
            <v-sheet class="pa-2 ma-2">
              <div>
                <h1 class="font-weight-bold">{{ staff.first_name }} {{ staff.last_name }}</h1>
              </div>
              <div v-if="staff.job_title !== null">
                <h4>{{ staff.job_title }}</h4>
                <h4>{{ staff.main_company }}</h4>
              </div>
            </v-sheet>
          </v-col>

          <!-- Second Column -->
          <v-col cols="12" md="auto" class="d-flex justify-center">
            <v-sheet class="pa-2 ma-2 d-flex justify-center">
              <v-avatar size="200">
                <v-img :src="profilePic" alt="Profile Picture"></v-img>
              </v-avatar>
            </v-sheet>
          </v-col>
        </v-row>

        <v-row class="mt-4 justify-center">

          <!-- First Column -->
          <v-col cols="12" md="7">
            <v-sheet class="ma-2">
              <InfoRow
                icon="mdi-cellphone"
                label="Mobile:"
                :content="'+' + staff.dialing_code + ' ' + staff.mobile_no"
                isTelLink
              />
              <InfoRow
                icon="mdi-email"
                label="Email:"
                :content="staff.email"
                isEmailLink
              />
              <!-- <InfoRowMulti
                icon="mdi-office-building"
                label="Company:"
                :content="company.company_name"
              /> -->
              <InfoRowAddress
                icon="mdi-map-marker"
                label="Address:"
                :content="company.office_address"
              />
              <InfoRowMulti
                icon="mdi-phone"
                label="Office:"
                :content="company.general_line"
                isTelLink
              />
              <InfoRowMulti
                icon="mdi-web"
                label="Website:"
                :content="company.homepage_url"
                isLink
              />
            </v-sheet>
          </v-col>

          <!-- Second Column -->
          <v-col cols="12" md="auto" class="align-self-center">
            <v-sheet class="pa-2 ma-2">
              <v-row class="d-flex justify-center mb-4">
                <v-btn @click="generateVCard" color="#FFDB15">Add to Contact</v-btn>
              </v-row>
              <!-- <v-row class="mt-4 d-flex justify-center align-center">
                <v-col cols="auto" class="d-flex justify-center">
                  <a href="https://apps.apple.com/hk/app/i-charge-hk/id6463661505" target="_blank">
                    <img
                      class="logo"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Download_on_the_App_Store_Badge.svg/135px-Download_on_the_App_Store_Badge.svg.png"
                      alt="Apple App Store"
                    />
                  </a>
                </v-col>
                <v-col cols="auto" class="d-flex justify-center">
                  <a href="https://play.google.com/store/apps/details?id=hk.icharge.technology" target="_blank">
                    <img
                      class="logo"
                      src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                      alt="Google Play Store"
                    />
                  </a>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                Download I-Charge Mobile APP
              </v-row> -->
            </v-sheet>
          </v-col>
          <div class="text-left"><h6>Copyright 2016-2025 I-Charge Solutions International.</h6></div>
        </v-row>
      </div>
    </v-container>
  </v-app>
</template>

<script>

import axios from 'axios';
import InfoRow from '../components/InfoRow.vue'
import InfoRowMulti from '@/components/InfoRowMulti.vue';
import InfoRowAddress from '@/components/InfoRowAddress.vue';

export default {
  components: {
    InfoRow,
    InfoRowMulti,
    InfoRowAddress
  },
  methods: {
    generateVCard() {
      // Create the vCard content
      let vCardContent = `BEGIN:VCARD\nVERSION:3.0\nFN:${this.staff.first_name} ${this.staff.last_name}\nN;CHARSET=UTF-8:${this.staff.last_name};${this.staff.first_name};;;\nORG:${this.company.company_name}\nLOGO;MEDIATYPE=image/png:https://i-charge.global/wp-content/uploads/2024/04/cropped-android-chrome-512x512-1-32x32.png\nTITLE:${this.staff.job_title}\nTEL;TYPE=Cell:+${this.staff.dialing_code}${this.staff.mobile_no}`
      if (this.staff.mobile_no_2 !== null) 
        vCardContent += `\nTEL;TYPE=Cell:+${this.staff.mobile_no_2}`
      if (typeof this.company.general_line === "object") {
        this.company.general_line.forEach((number) => {
          vCardContent += `\nTEL;TYPE=Work,VOICE:${number}`
        })
      } else {
        vCardContent += `\nTEL;TYPE=Work,VOICE:${this.company.general_line}`
      }
      vCardContent += `\nEMAIL;CHARSET=UTF-8;type=WORK,INTERNET:${this.staff.email}\nURL:${this.company.homepage_url}\nPHOTO;MEDIATYPE=image/png:${this.staff.profile_pic}\nEND:VCARD`;
      const base64VCard = btoa(vCardContent);
      const dataUri = `data:text/vcard;base64,${base64VCard}`;
      //window.open(dataUri);
      const link = document.createElement('a');
      link.href = dataUri;
      link.download = `contact_${this.staff.first_name}${this.staff.last_name}.vcf`;
      link.style.display = 'none'; // Hide the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    },
    replaceWithBr() {
      return this.haiku.replace(/\n/g, "<br />")
    }
  },

  data() {
    return {
      staff: {},
      company: {},
    };
  },

  created() {
    // Fetch staff data based on the route parameter (staffNumber)
    const staffNumber = this.$route.params.id;

    //const encryptedId = encrypt(this.$route.params.id)

    // API path
    axios.get(`https://vcard.i-charge.global/api/staff_vcard/${staffNumber}`) 
    // axios.get(`http://localhost:3000/staff_vcard/${staffNumber}`) 
            .then(response => {
                const conpanyJson = {
                  "company_name": [],
                  "office_address": [],
                  "general_line": [],
                  "homepage_url": []
                }
                Object.keys(response.data.companies).forEach((element, index) => {
                  if (response.data.companies[element].company_name === null || conpanyJson["company_name"].includes(response.data.companies[element].company_name)) return
                  conpanyJson["company_name"].push(response.data.companies[element].company_name)
                  if (response.data.companies[element].office_address === null || conpanyJson["office_address"].includes(response.data.companies[element].office_address)) return
                  conpanyJson["office_address"].push(response.data.companies[element].office_address)
                  if (response.data.companies[element].homepage_url === null || conpanyJson["homepage_url"].includes(response.data.companies[element].homepage_url)) return
                  conpanyJson["homepage_url"].push(response.data.companies[element].homepage_url)
                  if (response.data.companies[element].general_line === null || conpanyJson["general_line"].includes(response.data.companies[element].general_line)) return
                  conpanyJson["general_line"].push(response.data.companies[element].general_line)
                })
                this.staff = response.data.staff;
                this.company = conpanyJson;
            })
            .catch(error => {
                console.log(error);
            });
  },

  computed: {
    profilePic() {
      return this.staff.profile_pic && require(`../assets${this.staff.profile_pic}.png`)
    }
  },

  metaInfo: function() {
    return {
      title: `${this.staff.first_name} ${this.staff.last_name} - ICS Staff Card`,
      meta: [
        { name: "robots", content: "noindex" },
        { name: "og:title", content: "test" }
      ]
    }
  }
};
</script>

<style lang="scss">

.logo {
  width: 100px; /* Set the desired width */
  height: auto; /* Maintain aspect ratio */
  filter: grayscale(100%); /* Initially black and white */
  transition: filter 0.3s; /* Smooth transition on hover */
}
.logo:hover {
  filter: none; /* Change to full color on hover */
}

.inner-wrap{
  width: 90%;
  max-width: 100%;
}

@media screen and (max-width: 1200px) {
  .inner-wrap{
      width: 86%;
  }
}

@media screen and (max-width: 821px) {
  .inner-wrap{
      max-width: 97%;
  }
}

@media screen and (max-width: 576px) {
  .inner-wrap{
      max-width: 90%;
  }
  .company-logo{
      content:url("../assets/I-Charge Logo Container CMYK_Master Logo_H.png");
  }
}

.main-info{
  padding-left: 5%;
  font-weight: bold;
}
.sec-info{
  padding-right: 5px;
  padding-left: 5px;
}

a, a:hover, a:focus, a:active {
    text-decoration: none;
    color: rgba(0,0,0,.87);
}

hr {
  margin: 10px 5px !important;
  color: #a9a9a9 !important;
}

h6 {
  font-size: 0.8rem !important;
  padding: 0.5rem 0.2rem !important;
}

</style>

